<template>
  <div class="container">
    <div class="content-box">
      <el-row :gutter="5">
        <el-col :span="5">
          <el-menu
            :default-active="routePath"
            class="el-menu-vertical-demo"
            background-color="#d7e7f3"
            text-color="#343434"
            active-text-color="#0359AC"
            router
            @select="handleSelect"
          >
            <el-menu-item index="/quality/summary">
              <i class="iconfont icon-xueshengzongsuhuizong"></i>
              <span slot="title"> 学生综素成绩汇总</span>
            </el-menu-item>
            <el-menu-item index="/quality/progress">
              <i class="iconfont icon-jindu"></i>
              <span slot="title"> 综素工作完成进度</span>
            </el-menu-item>
            <el-menu-item index="/quality/morality" v-if="hasBtnPermission('301')">
              <i class="iconfont icon-3_11sixiangpinde"></i>
              <span slot="title"> 思想品德</span>
            </el-menu-item>
            <el-menu-item index="/quality/Academic" v-if="hasBtnPermission('309')">
              <i class="iconfont icon-xueyeshuiping"></i>
              <span slot="title"> 学业水平</span>
            </el-menu-item>
            <el-menu-item index="/quality/health" v-if="hasBtnPermission('320')">
              <i class="iconfont icon-shengxinjiankang"></i>
              <span slot="title"> 身心健康</span>
            </el-menu-item>
            <el-menu-item index="/quality/Artistic" v-if="hasBtnPermission('333')">
              <i class="iconfont icon-yishuxiuyang"></i>
              <span slot="title"> 艺术素养</span>
            </el-menu-item>
            <el-menu-item index="/quality/social" v-if="hasBtnPermission('341')">
              <i class="iconfont icon-shehuishijian"></i>
              <span slot="title"> 社会实践</span>
            </el-menu-item>
            <el-menu-item index="/quality/indexSystem">
              <i class="iconfont icon-tixi"></i>
              <span slot="title"> 评价指标体系</span>
            </el-menu-item>
            <el-menu-item index="/quality/psychology">
              <i class="iconfont icon-gongxiang"></i>
              <span slot="title"> 心理知识共享</span>
            </el-menu-item>
            <el-menu-item index="/quality/reading">
              <i class="iconfont icon-tuijian"></i>
              <span slot="title"> 中学生课外读物推荐</span>
            </el-menu-item>
            <el-menu-item index="/quality/video">
              <i class="el-icon-video-camera"></i>
              <span slot="title">中学生影视推荐</span>
            </el-menu-item>
            <!-- <el-menu-item index="/quality/graduation">
              <i class="iconfont icon-dangan"></i>
              <span slot="title"> 毕业成绩管理</span>
            </el-menu-item> -->
          </el-menu>
        </el-col>
        <el-col :span="19">
          <router-view />
        </el-col>
      </el-row>
    </div>
    <!-- <div class="no-more">没有更多了</div> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      routePath: "",
      powerList: [],
	  role: this.$store.getters.roles[0].roleKey
    };
  },
  created() {
    this.getPath();
    this.$store.dispatch('GetTermList')
    if(this.$store.getters.headTeacher == '是' || this.role == 'ban_wei') {
      this.powerList = ['301','309','320','333','341']
    }else {
      this.powerList = this.$store.getters.reviewAuthoritys
    }
  },
  watch: {
    $route: "getPath",
  },
  methods: {
    getPath() {
      if (this.$route.path.indexOf("/studentResults") != -1) {
        this.routePath = "/quality/morality";
      }else if(this.$route.path.indexOf("/healthStudent") != -1) {
        this.routePath = "/quality/health";
      }else if(this.$route.path.indexOf("/artStudent") != -1) {
        this.routePath = "/quality/Artistic";
      }else if(this.$route.path.indexOf("/academicStudent") != -1) {
        this.routePath = "/quality/Academic";
      }else if(this.$route.path.indexOf("/usualDetails") != -1) {
        this.routePath = "/quality/Academic";
      }else if(this.$route.path.indexOf("/summaryDetail") != -1) {
        this.routePath = "/quality/summary";
      }else if(this.$route.path.indexOf("/socialStudent") != -1) {
        this.routePath = "/quality/social";
      }else if(this.$route.path.indexOf("/experience") != -1) {
        this.routePath = "/quality/social";
      } else {
        this.routePath = this.$route.path;
      }
    },
    handleSelect(index) {
      // console.log(index);
    },
    hasBtnPermission(userPermission = '') {
      let powerList = this.powerList || [];  // 获取缓存的权限列表  
      return powerList.includes(userPermission) || false;
    }
  },
};
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  .content-box {
    background: rgba(206, 223, 236, 0.45);
    padding: 18px 18px 18px 0;
    border-radius: 0 0 6px 6px;
    .el-menu {
      border: none;
      .el-menu-item {
        font-weight: bold;
        border-left: 4px solid transparent;
        i {
          font-size: 22px;
          font-weight: normal;
          color: #343434;
        }
        &.is-active {
          border-left: 4px solid #198af7 !important;
          background-color: #c3dcef !important;
          i {
            color: #0359ac;
          }
        }
      }
    }
  }
  .no-more {
    height: 50px;
    text-align: center;
    color: rgba(25, 138, 247);
    line-height: 50px;
    background: #cedfec;
    opacity: 0.46;
    border-radius: 6px;
    margin-top: 14px;
  }
}
</style>